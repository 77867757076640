<template>
  <div>
    <stem-header/>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div class="step-content">
        <loading v-if="loading" :loading="loading" />
        <div v-if="!loading" class="container">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="form-group mb-34">
                <div class="form-group mb-34">
                  <validation-provider
                    name="Industry"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for="#">
                      Related Industry
                      <span class="required">*</span>
                    </label>
                    <multi-select
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      name="Industry"
                      id="industry"
                      @close="closeSelect"
                      :openDirection="'bottom'"
                      :searchable="false"
                      v-model="form.industry"
                      :options="industrys"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </div>
                      </template>
                    </multi-select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group mb-34">
                <label for>
                  Product Name
                  <span class="required">*</span>
                </label>
                <input spellcheck="true"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  placeholder="Type in the name of the product you are looking for"
                />
              </div>
              <div class="form-group mb-34">
                <validation-provider
                  name="units"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for="#">
                    Unitjj
                    <span class="required">*</span>
                  </label>
                  <multi-select
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    name="Industry"
                    id="industry"
                    @close="closeSelect"
                    :openDirection="'bottom'"
                    :searchable="false"
                    v-model="form.unit"
                    :options="units"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </div>
                    </template>
                  </multi-select>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
              <div class="form-group mb-34">
                <validation-provider
                  name="demand"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for>Demand</label>
                  <input spellcheck="true"
                    v-model.trim="form.demand"
                    type="text"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
              <div class="form-group mb-34">
                <validation-provider
                  name="desc"
                  :rules="{ itemDescription: true }"
                  v-slot="validationContext"
                >
                  <label for>Product Description</label>
                  <textarea spellcheck="true"
                    v-model.trim="form.desc"
                    type="text"
                    rows="7"
                    class="form-control"
                  ></textarea>
                   <div class="counter">
                   <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                  <div class="number-back-counter">
                    {{ counter }} / 1200
                  </div>
                  </div>
                </validation-provider>
              </div>
              <div
                class="form-group d-flex align-items-center justify-content-end"
                :class="{ 'justify-content-between': getUserList.length > 0 }"
              >
                <router-link
                  v-if="getUserList.length > 0"
                  :to="'/register-steps/step-3/products'"
                  class="d-block mr-2"
                  >{{$t("buttons.cancel")}}</router-link
                >
                <button
                  @click="handleSubmit(formSubmit)"
                  class="btn btn-primary"
                >
                  {{$t("buttons.save")}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import StepFooter from "../../components/register-step/step-footer";
import getUnit from "@/graphql/public/getUnit.gql";
import getIndustry from "@/graphql/public/getIndustry.gql";
import getInquiries from "@/graphql/me/getInquiries.gql";
import updateInquiry from "@/graphql/me/updateInquiry.gql";
import getInquiry from "@/graphql/me/getInquiry.gql";
import Loading from "@/components/global/loading";
import { mapMutations, mapGetters } from "vuex";
import StepHeader from '@/components/register-step/step-header.vue';
import _ from 'lodash';
export default {
  components: {
    StepFooter,
    Loading,
    StepHeader
  },
  data() {
    return {
      loading: true,
      itemId: null,
      form: {
        name: null,
        demand: null,
        desc: null,
        unit: null,
        industry: null,
      },
      industrys: [],
      units: []
    };
  },
  methods: {
    ...mapMutations({
      setUserList: "inquiries/setUserList",
    }),
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }
      return null;
    },
    closeSelect(value, id) {
      console.log(id);
      if (id == "industry") {
        if (!this.form.industry) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == "years") {
        if (!this.form.years) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == "positions") {
        if (!this.form.position) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },
    async getUnits() {
      try {
        let data = await this.$apollo.query(getUnit);
        let response = data("**.units");
        this.units = response;
        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
      }
    },
    async getIndustry() {
      try {
        let par = {
          type: "PRODUCT_AND_INQUIRY",
        };
        let data = await this.$apollo.query(getIndustry, par);
        let response = data("**.industries");
        this.industrys = response;
        console.log(data);
        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
      }
    },
    async getInquiries() {
      let payload = {
        limit: 10,
        filters: {},
      };
      let data = await this.$apollo.query(getInquiries, payload);
      let response = data("**.list");
      this.setUserList(response);
      return response;
    },
    async getItem(id) {
      let data = await this.$apollo.query(getInquiry, { id: id });
      let response = data("**.get");
      this.setSelectIndustry(response.industry.id);
      this.setSelectUnit(response.unit.id);
      this.form.demand = response.demand;
      this.form.desc = response.contents.find(
        (item) => item.default === true
      ).description;
      this.form.name = response.contents.find(
        (item) => item.default === true
      ).name;
    },
    setSelectIndustry(id) {
      let handler = this.industrys.find((item) => item.id === id);
      this.form.industry = handler;
    },
    setSelectUnit(id) {
      let handler = this.units.find((item) => item.id === id);
      this.form.unit = handler;
    },
    async formSubmit() {
      try {
        let inquiryData = {
          id: parseInt(this.itemId),
          industry_id: this.form.industry.id,
          unit_id: this.form.unit.id,
          demand: parseInt(this.form.demand),
          name: this.form.name,
          description: this.form.desc,
          name: this.form.name,
        };
        let data = await this.$apollo.mutate(updateInquiry, {
          inquiry: inquiryData,
        });
        await this.getInquiries();
        this.$router.push("/register-steps/step-3/products");
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      getUserList: "inquiries/getUserList",
    }),
    counter() {
        return _.size(this.form.desc);
    }
  },
  async created() {
    this.loading = true;
    this.itemId = this.$route.params.id;
    await this.getIndustry();
    await this.getUnits();

    await this.getItem(parseInt(this.itemId));
    this.loading = false;
  },
};
</script>

<style></style>
